import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAt,
  faMapMarked,
  faAngleDoubleDown,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons"

import AboutUs from "../components/AboutUs"
import ContactUs from "../components/ContactUs"
import CoreValues from "../components/CoreValues"
import CTAContact from "../components/CTAContact"
import HeroSection from "../components/HeroSection"
import OurProcess from "../components/OurProcess"
import TopServices from "../components/TopServices"
import WhyUs from "../components/WhyUs"

import servicesData from "../data/servicesData"
import valuesData from "../data/valuesData"
import stepsData from "../data/stepsData"

class IndexPage extends React.Component {
  componentDidMount() {
    import("react-ga").then(function(ReactGA) {
      ReactGA.initialize("UA-611766-15")
      ReactGA.pageview(window.location.pathname + window.location.search)
    })
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Home"
          keywords={[
            `auditores`,
            `asesores`,
            `contabilidad`,
            `contaduria publica`,
            `auditoria fiscal`,
            `auditores externos`,
            `contaduria`,
            `el salvador`,
            `fiscal`,
            `financiero`,
            `asesores financieros`,
          ]}
        />
        <HeroSection>
          <div className="section-overlay" />
          <Container className="container-with-overlay py-5">
            <Row>
              <Col className="text-white text-center py-5">
                <h5 className="pt-5">Opinión Profesional Independiente</h5>
                <h2 className="py-3 h1">Auditores y Asesores S.A. de C.V.</h2>
                <h5 className="py-3">
                  Establecidos desde <strong>1991</strong>, comprometidos con
                  nuestros clientes
                </h5>
                <a
                  href="/#contactUs"
                  className="btn btn-danger btn-lg my-3 px-5"
                >
                  Contáctanos
                </a>
                <div className="pt-5">
                  <FontAwesomeIcon icon={faAngleDoubleDown} size="2x" />
                </div>
              </Col>
            </Row>
          </Container>
        </HeroSection>
        <AboutUs
          title="El Salvador - Auditores y Asesores"
          smallText={`Somos una empresa salvadoreña con más de ${new Date().getFullYear() -
            1991} años de prestigio que nos respaldan en el área de Auditoría, Contabilidad, Impuestos, Consultoría. Contamos con profesionales altamente capacitados y a su disposición.`}
          subtitle="Comprometidos al 100% con Nuestros Clientes"
          text="Tenemos el respaldo de los clientes con los que hemos trabajado a través de tantos años que han depositado su confianza en nosotros y la continúan haciendo hasta el día de hoy."
          logoAboutUs={this.props.data.logoAboutUs.childImageSharp.fluid}
        />
        <CoreValues values={valuesData} />
        <CTAContact
          title="Conviertete en uno de nuestros clientes satisfechos"
          url="/#contactUs"
          btnText="CONTÁCTANOS"
          className="CTAContact CTAContact-form"
        >
          <p className="text-white">
            Nos gustaría conocer las necesidades de su empresa, ayudarle a
            implementar procesos eficientes para hacerla crecer de manera
            exponencial.
          </p>
        </CTAContact>
        <TopServices
          title="Obtén Nuestros Excelentes Servicios"
          subtitle="Nuestros Servicios más importantes se dividen en cuatro categorías. Los detallamos a continuación:"
          services={servicesData}
          images={{
            auditoriaFinanciera: this.props.data.auditoriaFinancieraImage
              .childImageSharp.fluid,
            auditoriaFiscal: this.props.data.auditoriaFiscalImage
              .childImageSharp.fluid,
            auditoriasEspeciales: this.props.data.auditoriasEspecialesImage
              .childImageSharp.fluid,
            consultoria: this.props.data.consultoriaImage.childImageSharp.fluid,
          }}
        />
        <OurProcess
          title="Nuestro Proceso"
          subtitle="Atención Directa y Personalizada. 3 Pasos para el crecimiento empresarial."
          steps={stepsData}
          image={this.props.data.blocksImage.childImageSharp.fluid}
        />
        <WhyUs
          title="¿Por qué elegirnos?"
          subtitle="Somos la Compañía que le ofrece la más alta calidad en Nuestros Servicios Profesionales"
          image={this.props.data.moneyGrowsImage.childImageSharp.fluid}
          footer="¡Permítanos hacer crecer su negocio!"
        >
          <p className="text-center">
            Contamos con un equipo de profesionales altamente capacitados;
            muchos años de experiencia en el campo de la contaduría pública,
            atención personalizada; valores éticos y honestidad comprobada.
          </p>
          <p className="text-center">
            Brindamos un servicio excepcional y no descansaremos hasta estar
            completamente seguros que hemos brindado lo mejor de nuestra
            compañía.
          </p>
        </WhyUs>
        <CTAContact
          title="Creamos Empresas, las hacemos Crecer y obtener los mejores Resultados"
          url="tel:+50322602709"
          btnText="+503 2260 2709"
          className="CTAContact CTAContact-phone"
        >
          <p className="text-white">
            Contamos con servicios de Constitución de Empresas y te acompañamos
            durante todo el camino. Somos tu mejor aliado.
          </p>
          <p className="text-white">¡Llámanos! será un placer atenderte</p>
        </CTAContact>
        <ContactUs
          title="¿Tienes Preguntas o Comentarios?"
          subtitle="No dudes en contactarnos. Nos gustaría conocer tus preguntas y comentarios."
          url="https://ebq1b9zzy6.execute-api.us-east-1.amazonaws.com/prod/contact"
        >
          <Container fluid>
            <Row className="align-items-center">
              <Col xs={4} className="text-center mb-3">
                <a
                  href="https://goo.gl/maps/46487SSwWZBAjJ5e9"
                  alt="Auditores y Asesores El Salvador"
                  title="Auditores y Asesores El Salvador"
                >
                  <div className="mb-3">
                    <FontAwesomeIcon
                      icon={faMapMarked}
                      size="4x"
                      className="text-danger"
                    />
                  </div>
                </a>
              </Col>
              <Col xs={8}>
                <a
                  href="https://goo.gl/maps/46487SSwWZBAjJ5e9"
                  alt="Auditores y Asesores El Salvador"
                  title="Auditores y Asesores El Salvador"
                >
                  <p>
                    Boulevard de Los Héroes, Urb. San Ernesto, Pje. San Carlos
                    #137 San Salvador El Salvador.
                  </p>
                </a>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={4} className="text-center mb-3">
                <a
                  href="mailto:auditores@auditoresyasesores.com.sv"
                  title="Email Auditores y Asesores"
                >
                  <div className="mb-3">
                    <FontAwesomeIcon
                      icon={faAt}
                      size="4x"
                      className="text-danger"
                    />
                  </div>
                </a>
              </Col>
              <Col xs={8}>
                <a
                  href="mailto:auditores@auditoresyasesores.com.sv"
                  title="Email Auditores y Asesores"
                >
                  <p style={{ wordBreak: "break-word" }}>
                    auditores@auditoresyasesores.com.sv
                  </p>
                </a>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={4} className="text-center mb-3">
                <a href="tel:+50322602709" title="Llamar Auditores y Asesores">
                  <div className="mb-3">
                    <FontAwesomeIcon
                      icon={faPhoneSquare}
                      size="4x"
                      className="text-danger"
                    />
                  </div>
                </a>
              </Col>
              <Col xs={8}>
                <a href="tel:+50322602709" title="Llamar Auditores y Asesores">
                  <p>Teléfonos: +(503) 2260-2709</p>
                </a>
              </Col>
            </Row>
          </Container>
        </ContactUs>
      </Layout>
    )
  }
}

export const query = graphql`
  query IndexQuery {
    logoAboutUs: file(relativePath: { eq: "logo-about-us.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auditoriaFinancieraImage: file(
      relativePath: { eq: "auditoriaFinanciera.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auditoriaFiscalImage: file(relativePath: { eq: "auditoriaFiscal.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auditoriasEspecialesImage: file(
      relativePath: { eq: "auditoriasEspeciales.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    consultoriaImage: file(relativePath: { eq: "consultoria.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blocksImage: file(relativePath: { eq: "blocks.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    moneyGrowsImage: file(relativePath: { eq: "moneyGrows.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
