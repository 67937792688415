export default [
  {
    title: "Identificación de Problemas o Necesidades",
    text:
      "Reunión Inicial para conocer tu negocio, identificar los puntos débiles y necesidades de su empresa.",
  },
  {
    title: "Análisis y Presentación de Servicios",
    text:
      "Nuestros años de experiencia nos permiten analizar los problemas o necesidades de su empresa, identificar los Servicios que necesitan para optimizar su funcionamiento y fomentar su crecimiento.",
  },
  {
    title: "Puesta en marcha de nuestros Servicios",
    text:
      "Una vez que hemos establecido y definido el proceso a implementar así como los indicadores que nos permitirán medir los resultados obtenidos, ponemos en marcha nuestros servicios para la búsqueda y corrección de las deficiencias encontradas.",
  },
]
