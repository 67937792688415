import {
  faMountain,
  faBullseye,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons"

export default [
  {
    icon: faBullseye,
    title: "Visión",
    text:
      "Ser una de las principales firmas de Auditoría y Consultoría, Finaciera y Fiscal. Para ello, nuestro personal debe estar capacitado en todas las áreas de Contaduría Pública, Finanzas, Legislación, Informática, Idiomas, y areas afines. A fin de conseguir una posición de dominio en el mercado de los servicios profesionales independientes.",
  },
  {
    icon: faMountain,
    title: "Misión",
    text:
      "Brindar servicios altamente calificados, con eficiencia y oportunidad, en la Auditoría Externa y Consultoría en el área de la Contaduría Pública y campos de trabajo relacionados.",
  },
  {
    icon: faHandshake,
    title: "Valores",
    text:
      "Confianza, Honestidad, Integridad, Cuidado Profesional, Experiencia, Compromiso y Entrega.",
  },
]
