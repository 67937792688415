import React from "react"
import { Col, Container, Row } from "reactstrap"
import ContactForm from "../ContactForm"

const ContactUs = props => (
  <section className="ContactUs bg-light">
    <Container className="py-5" id="contactUs">
      <h2 className="text-center text-danger mb-3">{props.title}</h2>
      <p className="text-center my-4">{props.subtitle}</p>
      <Row className="align-items-center">
        <Col xs={12} lg={6} className="mb-3">
          <Container>
            <Row>{props.children}</Row>
          </Container>
        </Col>
        <Col>
          <h4 className="text-center mb-4">Formulario de Contacto en Línea</h4>
          <ContactForm url={props.url} />
        </Col>
      </Row>
    </Container>
  </section>
)

export default ContactUs
