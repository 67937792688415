import React from "react"
import { Col, Container, Row } from "reactstrap"
import Img from "gatsby-image"

const OurProcess = props => (
  <section className="OurProcess">
    <Container className="py-3" id="ourProcess">
      <h2 className="text-center text-danger pt-5 pb-3">{props.title}</h2>
      <p className="text-center text-white">{props.subtitle}</p>
      <Row>
        <Col xs={12} lg={6} className="text-center mb-5">
          <Img fluid={props.image} alt={props.title} title={props.title} />
        </Col>
        <Col xs={12} lg={6} className="mb">
          <Container>
            {props.steps.map((step, index) => (
              <Row key={index}>
                <Col xs={1}>
                  <h4 className="text-danger">0{index}</h4>
                </Col>
                <Col xs={11}>
                  <h4 className="text-white">{step.title}</h4>
                  <p className="text-secondary">{step.text}</p>
                </Col>
              </Row>
            ))}
          </Container>
        </Col>
      </Row>
    </Container>
  </section>
)

export default OurProcess
