import React from "react"
import { Col, Container, Row } from "reactstrap"
import Img from "gatsby-image"

const AboutUs = props => (
  <section className="AboutUs">
    <Container className="pt-5" id="aboutUs">
      <h2 className="text-danger text-center">{props.title}</h2>
      <p className="text-center my-4">{props.smallText}</p>
      <Row className="py-5">
        <Col xs={12} md={6} className="mb-3">
          <h3 className="text-center text-dark">{props.subtitle}</h3>
          <p className="my-3 text-justify">{props.text}</p>
        </Col>
        <Col xs={12} md={6} className="text-center">
        <Img className="img-fluid" fluid={props.logoAboutUs} alt={props.title} title={props.title} />
        </Col>
      </Row>
    </Container>
  </section>
)

export default AboutUs
