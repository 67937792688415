import React from "react"
import { Col, Container, Row } from "reactstrap"

const CTAContactUs = props => (
  <section className={props.className}>
    <div className="section-overlay" />
    <Container className="container-with-overlay py-5">
      <Row>
        <Col className="text-center text-white py-3">
          <h2 className="mb-4">{props.title}</h2>
          {props.children}
          <a
            href={props.url}
            title={props.btnText}
            alt={props.btnText}
            className="btn btn-danger btn-lg"
          >
            {props.btnText}
          </a>
        </Col>
      </Row>
    </Container>
  </section>
)

export default CTAContactUs
