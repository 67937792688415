import React from "react"
import { Container, Row } from "reactstrap"

import ServicePreview from "../ServicePreview"

const TopServices = props => (
  <section className="TopServices">
    <Container className="py-5">
      <h2 className="text-center mt-5 mb-3 text-danger">{props.title}</h2>
      <p className="text-center">{props.subtitle}</p>
      <Row className="d-flex">
        {props.services.map(service => (
          <ServicePreview key={service.title} {...service} image={props.images[service.imageUrl]} />
        ))}
      </Row>
    </Container>
  </section>
)

export default TopServices
