import React from "react"
import { Col, Container, Row } from "reactstrap"
import Img from "gatsby-image"

const WhyUs = props => (
  <section className="WhyUs">
    <Container className="py-5">
      <h2 className="text-center text-danger mb-4">{props.title}</h2>
      <h4 className="text-center text-dark mb-4">{props.subtitle}</h4>
      <Row>
        <Col xs={12} md={6} className="py-3">
          {props.children}
        </Col>
        <Col>
          <Img
            className="text-center img-fluid"
            fluid={props.image}
            alt={props.title}
            title={props.title}
          />
        </Col>
      </Row>
      <h4 className="text-center text-dark py-5">{props.footer}</h4>
    </Container>
  </section>
)

export default WhyUs
