import React from "react"
import { Col, Container, Row } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CoreValues = props => (
  <section className="CoreValues">
    <Container className="py-3">
      <Row>
        {props.values.map(value => (
          <Col md="4" key={value.title}>
            <div className="text-center text-danger">
              <FontAwesomeIcon
                icon={value.icon}
                size="4x"
                className="text-danger"
              />
            </div>
            <h4 className="text-center my-3 text-dark">{value.title}</h4>
            <p className="text-justify">{value.text}</p>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
)

export default CoreValues
