import React from "react"
import {
  Card,
  CardBody,
  Col,
  CardFooter,
  CardTitle,
  CardText,
} from "reactstrap"
import Img from "gatsby-image"

class ServicePreview extends React.Component {
  render() {
    return (
      <Col xs={12} md={6} className="mb-3" >
        <Card style={{ height: "100%" }} id={this.props.anchor}>
          <Img
            fluid={this.props.image}
            alt={this.props.title}
            title={this.props.title}
          />
          <CardBody>
            <CardTitle>
              <h2 className="text-center text-danger h4 mb-3">
                {this.props.title}
              </h2>
            </CardTitle>
            <CardText className="text-justify">{this.props.text}</CardText>
          </CardBody>
          <CardFooter>
            <small className="text-muted">Leer más</small>
          </CardFooter>
        </Card>
      </Col>
    )
  }
}

export default ServicePreview
