import React, { Component } from "react"
import { Alert, Button, Col, Form, FormGroup, Input } from "reactstrap"
import axios from "axios"

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    alert: "",
    color: "",
  }

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
    })

  handleSubmit = event => {
    event.preventDefault()

    axios
      .post(this.props.url, {
        ...this.state,
      })
      .then(response => {
        this.setState({
          alert:
            "Muchas gracias por contáctarnos. Le responderemos a la brevedad posible.",
          color: "success",
          name: "",
          email: "",
          message: "",
        })
      })
      .catch(err => {
        this.setState({
          alert:
            "No fue posible enviar su mensaje. Por favor intente denuevo, o por favor contáctenos via teléfonica al 2260-2709.",
          color: "danger",
        })
      })
  }

  render() {
    return (
      <Form className="pl-md-3" onSubmit={this.handleSubmit}>
        {this.state.alert ? (
          <Alert color={this.state.color}>{this.state.alert}</Alert>
        ) : null}
        <FormGroup row>
          <Col sm={12}>
            <Input
              bsSize="lg"
              aria-label="Su nombre"
              type="text"
              name="name"
              id="inputName"
              required
              placeholder="Su Nombre"
              onChange={this.handleChange}
              value={this.state.name}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12}>
            <Input
              bsSize="lg"
              aria-label="Su Email"
              type="email"
              name="email"
              id="inputEmail"
              required
              placeholder="email@domain.com"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12}>
            <Input
              bsSize="lg"
              aria-label="Su Mensaje"
              type="textarea"
              name="message"
              id="inputMessage"
              rows="4"
              placeholder="Su Mensaje"
              onChange={this.handleChange}
              value={this.state.message}
            />
          </Col>
        </FormGroup>
        <FormGroup check row>
          <Col xs={12} className="d-flex justify-content-center">
            <Button color="danger" size="lg" aria-label="Enviar">
              Enviar Mensaje
            </Button>
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

export default ContactForm
