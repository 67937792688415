export default [
  {
    imageUrl: "auditoriaFinanciera",
    title: "Auditoría Financiera",
    text:
      "Consiste principalmente en la evaluación de documentos, operaciones y registros de la entidad para determinar si estos reflejan razonablemente su situación financiera y los resultados de sus operaciones a fin de emitir una opinión sobre la razonabilidad de los Estados Financieros.",
    anchor: "auditoriaFinanciera",
  },
  {
    imageUrl: "auditoriaFiscal",
    title: "Auditoría Fiscal",
    text:
      "El objetivo de la Auditoría Fiscal consiste en emitir una opinión sobre el cumplimiento de las obligaciones tributarias por parte de una entidad económica a partir de la investigación selectiva de las cuentas del balance, cuentas de resultados, documentación, registro y operaciones efectuadas por una empresa.",
    anchor: "auditoriaFiscal",
  },
  {
    imageUrl: "auditoriasEspeciales",
    title: "Auditorías Especiales a Rubros Específicos",
    text:
      "Consiste en rendir un informe a la dirección del ente económico sobre la auditoría practicada a uno o varios rubros específicos de los Estados Financieros. El campo de acción está determinado por el ente económico que contrata dicho servicio.",
    anchor: "auditoriasEspcialesARubrosEspecificos",
  },
  {
    imageUrl: "consultoria",
    title: "Consultoría",
    text:
      "Nuestra firma cuenta con un equipo de consultores especialistas dispuestos a prestar asistencia a los dirigentes de los negocios en el logro de sus objetivos con el máximo de efectividad. Los servicios prestados cubren las ramas de Contaduría Pública, Finanzas, Legislación Fiscal, aspectos mercantiles, costos industriales, constitución y legalización de empresas entre otros.",
    anchor: "consultoria",
  },
]
